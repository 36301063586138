import { navigate } from "gatsby";
import React from "react";

import * as AddAreaModalStyle from "./AddArea.module.scss";

interface Props {
  onAdd: (url: string) => void;
}

const Index = ({ onAdd }: Props) => {
  return (
    <div className={AddAreaModalStyle.container}>
      <h6>Add Property</h6>
      <button
        className={`${AddAreaModalStyle.btn} ${AddAreaModalStyle.btn__parking}`}
        onClick={() => {
          navigate("/add/PARKING");
        }}
      >
        Parking
      </button>
      <button
        className={`${AddAreaModalStyle.btn} ${AddAreaModalStyle.btn__charging}`}
        onClick={() => {
          navigate("/add/CHARGING");
        }}
      >
        Charging
      </button>
      <button
        className={`${AddAreaModalStyle.btn} ${AddAreaModalStyle.btn__society}`}
        onClick={() => {
          navigate("/add/SOCIETY");
        }}
      >
        Society
      </button>
    </div>
  );
};

export default Index;
