import React, { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { Downgraded, useState as useHookState } from "@hookstate/core";
import Swal from "sweetalert2";

import AppState from "../../States/AppState";

import ParkingAreaCard from "../ParkingCard";
import Loader from "../Loader";
import Modal from "../Modal";
import AddAreaModal from "../Modals/AddArea";

import { ParkingArea } from "../../Interfaces/ParkingArea";
import { GetUserAreasQuery } from "../../Query/ParkingArea";

import AddImage from "../../images/plus_icon2@2x.png";

import * as ParkingAreasStyle from "./ParkingAreas.module.scss";

import Server from "../../utils/Server";
import { AxiosError } from "axios";
import { popoverAlert } from "../../utils/Alert";
import { ManagerArea, User } from "../../Interfaces/User";

import Logger from "../../utils/Logger";

const Index = () => {
  const user = useHookState(AppState).attach(Downgraded).user.get();
  const [parkingAreas, setParkingAreas] = useState<ParkingArea[]>([]);

  const [managerAreas, setManagersAreas] = useState<ManagerArea[]>();
  const [isAddModal, setIsAddModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { data, loading, error, fetchMore, refetch } = useQuery<{ user: User }>(
    GetUserAreasQuery,
    {
      variables: {
        id: user?.id,
        areaStatus: ["DEACTIVE", "ACTIVE", "PENDING", "REVIEW", "REJECTED"],
        manageStatus: "ACTIVE",
      },
    }
  );

  useEffect(() => {
    if (error && user?.id) {
      Logger.showError(error);
    }
  }, [error]);

  const changeParkingStatus = (
    parkingId: string,
    status: "ACTIVE" | "DEACTIVE" | "REVIEW"
  ) => {
    setIsLoading(true);
    const shortParkingId = parkingId.split("/")[2];
    Server.patch(`/parkingArea/${shortParkingId}`, {
      status,
    })
      .then(() => {
        popoverAlert({
          icon: "success",
          title: "Success",
          msg:
            (status === "ACTIVE" && "Area Is Activated.") ||
            (status === "DEACTIVE" && "Area Is Deactivated.") ||
            (status === "REVIEW" && "Area Is Under Review.") ||
            "",
        });
        setParkingAreas(
          parkingAreas.map((parkingArea) =>
            parkingArea.id === parkingId
              ? { ...parkingArea, status: { code: status } }
              : parkingArea
          )
        );
      })
      .catch((error: AxiosError) => {
        Logger.showError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onDelete = (areaId: string) => {
    Swal.fire({
      type: "warning",
      title: "Warning",
      text: "Area You Sure",
      showCancelButton: true,
    }).then((e) => {
      if (e.value) {
        const shortParkingId = areaId.split("/")[2];
        Server.del(`/parkingArea/${shortParkingId}/delete`)
          .then(() => {
            setParkingAreas(parkingAreas?.filter(({ id }) => id !== areaId));
          })
          .catch((error: AxiosError) => {
            popoverAlert({ msg: error.response?.data.detail, icon: "error" });
          });
      }
    });
  };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data?.user?.parkingAreas && !loading) {
      setParkingAreas(data.user.parkingAreas?.collection);
      setManagersAreas(data.user.parkingAreaManagers?.collection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div className={ParkingAreasStyle.container}>
        <div className={ParkingAreasStyle.header}>
          <h3 className="color-primary boldText textLg">
            My Parking Area/Charging Station
          </h3>
          {!user?.roles?.includes("ROLE_GATEKEEPER") &&
            !user?.roles?.includes("ROLE_HELPER") &&
            !user?.roles?.includes("ROLE_MANAGER_HELPDESK") && (
              <div>
                <button
                  onClick={() => {
                    setIsAddModal(true);
                  }}
                  className={`${ParkingAreasStyle.btn__add}  no-margin text-end`}
                >
                  <h3 className="color-primary boldText textMd text-end">
                    Add New
                  </h3>
                  <img src={AddImage} alt="add" width={32} height={32} />
                </button>
              </div>
            )}
        </div>
        {!parkingAreas.length && (
          <div className="color-primary boldText textMd">No Parking Areas</div>
        )}
        <div className={ParkingAreasStyle.content}>
          {parkingAreas?.map((parking) => (
            <ParkingAreaCard
              key={`parking${parking.id}`}
              parkingArea={parking}
              onClickDelete={() => {
                onDelete(parking.id);
              }}
              onRequestApproval={() => {
                changeParkingStatus(parking.id, "REVIEW");
              }}
              onClickToggle={() => {
                if (
                  parking?.status?.code === "ACTIVE" ||
                  parking?.status?.code === "DEACTIVE"
                ) {
                  changeParkingStatus(
                    parking.id,
                    parking.status.code === "ACTIVE" ? "DEACTIVE" : "ACTIVE"
                  );
                }
              }}
            />
          ))}
          {managerAreas?.map(({ id, parkingArea, role }) => (
            <ParkingAreaCard
              key={`manager/${id}`}
              parkingArea={parkingArea}
              manager={{
                id: id,
                role: role,
              }}
            />
          ))}
        </div>
      </div>

      <Loader isLoading={loading || isLoading} />
      <Modal
        isOpen={isAddModal}
        title="Choose Property Type"
        onClose={() => {
          setIsAddModal(false);
        }}
      >
        <AddAreaModal onAdd={() => {}} />
      </Modal>
    </>
  );
};

export default Index;
