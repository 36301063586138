// extracted by mini-css-extract-plugin
export var card = "ParkingCard-module--card--mubMs";
export var card__header = "ParkingCard-module--card__header--bZfZ0";
export var card__header_title = "ParkingCard-module--card__header_title--CrRqa";
export var card__body = "ParkingCard-module--card__body--SkFDp";
export var card__body_action = "ParkingCard-module--card__body_action--mNdSw";
export var card__body_action_totalBay = "ParkingCard-module--card__body_action_totalBay--RgxC9";
export var card__body_action_btns = "ParkingCard-module--card__body_action_btns--h581o";
export var card__body_price = "ParkingCard-module--card__body_price--FMyAh";
export var card__body_price_item = "ParkingCard-module--card__body_price_item--5agGH";
export var card__body_image = "ParkingCard-module--card__body_image--8kC8E";
export var card__body_image_container = "ParkingCard-module--card__body_image_container--N-NIp";
export var btn__reject = "ParkingCard-module--btn__reject--9KJFz";
export var btn__review = "ParkingCard-module--btn__review--pCJog";
export var btn__edit = "ParkingCard-module--btn__edit--4u0JL";
export var rating = "ParkingCard-module--rating--X5RI-";
export var toggle = "ParkingCard-module--toggle--gL1v2";
export var society = "ParkingCard-module--society--9Uqvr";
export var price = "ParkingCard-module--price--u+WXD";
export var gates = "ParkingCard-module--gates--jqzip";
export var gates__item = "ParkingCard-module--gates__item--wBIet";