import React, { useEffect } from "react";
import { Downgraded, useState as useHookState } from "@hookstate/core";

import Layout from "../Components/Layout";
import ParkingAreas from "../Components/ParkingAreas";
import AppState from "../States/AppState";
import { getImageUrl } from "../utils/GetPhotoUrl";

const Index = () => {
  return (
    <Layout isPrivate showHeader>
      <ParkingAreas />
    </Layout>
  );
};

export default Index;
