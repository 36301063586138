import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { navigate } from "gatsby";
import {
  faInfoCircle,
  faStar,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";

import ToggleButton from "../ToggleButton";
import Modal from "../Modal";

import { Gate, ParkingArea } from "../../Interfaces/ParkingArea";

import DeleteIcon from "../../images/delete_dark_icon@2x.png";
import EditButton from "../../images/Edit_file_photo@2x.png";
import BikeIcon from "../../images/twowheeler_48@2x.png";
import CarIcon from "../../images/car_48@2x.png";
import BusIcon from "../../images/bus_48@2x.png";
import NoMeidaImg from "../../images/no_image_icon@2x.png";

import * as ParkingCardStyle from "./ParkingCard.module.scss";

import { gql, useLazyQuery } from "@apollo/client";
import AppState from "../../States/AppState";
import Logger from "../../utils/Logger";

interface GateManagers {
  gateManagers: {
    collection: {
      id: string;
      gate: Gate;
    }[];
  };
}

const GetManagerGates = gql`
  query GetManagerGates($manager: String, $parkingArea: String) {
    gateManagers(manager: $manager, parkingArea: $parkingArea) {
      collection {
        id
        gate {
          id
          name
        }
      }
    }
  }
`;
interface Props {
  parkingArea: ParkingArea;
  onRequestApproval?: () => void;
  onClickDelete?: () => void;
  onClickToggle?: () => void;
  manager?: {
    id: string;
    role: string[];
  };
}

const Index = ({
  parkingArea,
  manager,
  onClickDelete,
  onClickToggle,
  onRequestApproval,
}: Props) => {
  const [isGateOptions, setIsGateOptions] = useState(false);
  const getVehicelImg = (type: string) => {
    if (type === "2W")
      return <img src={BikeIcon} alt="" width={48} height={48} />;
    if (type === "4W")
      return <img src={CarIcon} alt="" width={48} height={48} />;
    if (type === "OTHER")
      return <img src={BusIcon} alt="" width={48} height={48} />;
  };

  const [getManagerGates, { data, loading, error }] =
    useLazyQuery<GateManagers>(GetManagerGates);

  const getManagerUrl = (role: string) => {
    if (role === "visitor" && manager?.id) {
      if (!data?.gateManagers.collection.length) {
        return Logger.showError(
          "You Are Not Assigned To Any Gate Ask Society Owner To Add You"
        );
      }
      if (data?.gateManagers.collection.length === 1) {
        return navigate(
          `/society/${role}/${
            parkingArea.id.split("/")[2]
          }/${encodeURIComponent(parkingArea.name)}/${
            data.gateManagers.collection[0]?.gate.id.split("/")[2]
          }`
        );
      }
      if (data?.gateManagers && data?.gateManagers.collection.length > 1) {
        return setIsGateOptions(true);
      }
    } else {
      return navigate(
        `/society/${role}/${parkingArea.id.split("/")[2]}/${encodeURIComponent(
          parkingArea.name
        )}/${manager?.id?.split("/")[2] || "_"}`
      );
    }
  };

  useEffect(() => {
    if (manager?.role.includes("ROLE_GATEKEEPER") && manager.id) {
      getManagerGates({
        variables: {
          manager: AppState.user.get()?.id,
          parkingArea: parkingArea.id,
        },
      })
        .then((res) => {
          console.log(res.data?.gateManagers.collection);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <>
      <div className={ParkingCardStyle.card}>
        <div className={`${ParkingCardStyle.card__header} grediant1`}>
          <div className={ParkingCardStyle.card__header_title}>
            <span className="text-capitalize">{parkingArea.name}</span>
            <div className={ParkingCardStyle.rating}>
              <span>{parkingArea?.rating || 0}</span>
              <FontAwesomeIcon icon={faStar} />
            </div>
          </div>
          <div>
            {!manager?.id && (
              <button onClick={onClickDelete}>
                <img src={DeleteIcon} alt="" width={24} />
              </button>
            )}
          </div>
        </div>
        <div className={`${ParkingCardStyle.card__body} padding`}>
          <div className={ParkingCardStyle.card__body_action}>
            {parkingArea.category?.code !== "SOCIETY" && (
              <div className={ParkingCardStyle.card__body_action_totalBay}>
                <h5 className={"boldText color-primary textSm"}>
                  {parkingArea.category?.code === "PARKING" &&
                    `No. of Parking Bays: ${parkingArea?.totalSpot}`}
                  {parkingArea.category?.code === "CHARGING" &&
                    `No. of Charging Station: ${parkingArea?.totalSpot}`}
                </h5>
              </div>
            )}

            <div className={ParkingCardStyle.card__body_action_btns}>
              {!manager?.id && (
                <button
                  onClick={() =>
                    navigate(
                      `edit/${parkingArea.category?.code}/${
                        parkingArea.id.split("/")[2]
                      }`
                    )
                  }
                  className={ParkingCardStyle.btn__edit}
                >
                  <img src={EditButton} alt="" width={28} />
                  <span>Edit</span>
                </button>
              )}

              {onClickToggle &&
                ["ACTIVE", "DEACTIVE"].includes(
                  parkingArea.status?.code || ""
                ) &&
                parkingArea.category?.code !== "SOCIETY" && (
                  <div className={ParkingCardStyle.toggle}>
                    <ToggleButton
                      color="Tertiary"
                      selected={parkingArea.status?.code === "ACTIVE"}
                      toggleSelected={onClickToggle}
                      isDisable={
                        parkingArea?.status?.code !== "ACTIVE" &&
                        parkingArea?.status?.code !== "DEACTIVE"
                      }
                    />
                    <span>{parkingArea.status?.code}</span>
                  </div>
                )}

              {onRequestApproval && parkingArea.status?.code === "PENDING" && (
                <button
                  onClick={onRequestApproval}
                  className={ParkingCardStyle.btn__edit}
                >
                  <FontAwesomeIcon icon={faThumbsUp} size="2x" />
                  <span>Request Approval</span>
                </button>
              )}

              {parkingArea.status?.code === "REVIEW" && (
                <button
                  disabled
                  onClick={() => {}}
                  className={`${ParkingCardStyle.btn__edit} ${ParkingCardStyle.btn__review}`}
                >
                  <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                  <span>Under Review</span>
                </button>
              )}

              {parkingArea.status?.code === "REJECTED" && (
                <button
                  disabled
                  onClick={() => {}}
                  className={`${ParkingCardStyle.btn__edit} ${ParkingCardStyle.btn__reject}`}
                >
                  <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                  <span>Rejected</span>
                </button>
              )}
            </div>
          </div>
          {parkingArea.category?.code !== "SOCIETY" ? (
            <div className={ParkingCardStyle.card__body_price}>
              {parkingArea.parkingActivePlans?.collection
                .reduce(
                  (a, { vehicle }) =>
                    a.includes(vehicle.code) ? a : [...a, vehicle.code],
                  Array()
                )
                .map((vType, i) => (
                  <div
                    key={`${vType}/${i}`}
                    className={ParkingCardStyle.card__body_price_item}
                  >
                    {getVehicelImg(vType)}
                    {parkingArea.parkingActivePlans?.collection
                      .filter(({ vehicle }) => vehicle.code === vType)
                      .sort(({ priceType: { code } }) =>
                        code === "PER_HOUR" ? -1 : 2
                      )
                      .map(({ id, price, priceType }) => (
                        <div key={`${id}/${price}`}>
                          <span className={ParkingCardStyle.price}>
                            {price}/
                            {priceType.code === "PER_DAY" ? "day" : "hr"}
                          </span>
                        </div>
                      ))}
                  </div>
                ))}
            </div>
          ) : (
            <div className={ParkingCardStyle.society}>
              {!manager?.id &&
              ["ACTIVE", "DEACTIVE"].includes(
                parkingArea.status?.code || ""
              ) ? (
                <>
                  {[
                    "Gates",
                    "Domestic Help",
                    "Help Desk",
                    "Managers",
                    "Residents",
                    "Notice Board",
                  ].map((text, i) => (
                    <button
                      key={`${text}/${i}`}
                      onClick={() => {
                        navigate(
                          `/society/${text.replace(" ", "").toLowerCase()}/${
                            parkingArea.id.split("/")[2]
                          }/${encodeURIComponent(parkingArea.name)}/_/owner`
                        );
                      }}
                    >
                      {text}
                    </button>
                  ))}
                </>
              ) : (
                <>
                  {manager?.role.includes("ROLE_GATEKEEPER") && (
                    <>
                      {data?.gateManagers.collection.map(({ gate }) => (
                        <button
                          key={gate.id}
                          onClick={() => {
                            navigate(
                              `/society/visitor/${
                                parkingArea.id.split("/")[2]
                              }/${encodeURIComponent(parkingArea.name)}/${
                                gate.id.split("/")[2]
                              }/gatekeeper`
                            );
                          }}
                        >
                          {gate.name}
                        </button>
                      ))}
                    </>
                  )}

                  {manager?.role.includes("ROLE_HELPER") && (
                    <button
                      onClick={() => {
                        navigate(
                          `/society/visitor/${
                            parkingArea.id.split("/")[2]
                          }/${encodeURIComponent(parkingArea.name)}/${
                            AppState.user.get()?.id?.split("/")[2]
                          }/helper`
                        );
                      }}
                    >
                      Help Desk
                    </button>
                  )}

                  {manager?.role.includes("ROLE_MANAGER_HELPDESK") && (
                    <button
                      onClick={() => {
                        navigate(
                          `/society/helpdesk/${
                            parkingArea.id.split("/")[2]
                          }/${encodeURIComponent(parkingArea.name)}/_/helpdesk`
                        );
                      }}
                    >
                      Help Desk
                    </button>
                  )}
                </>
              )}
            </div>
          )}

          <div className={ParkingCardStyle.card__body_image}>
            <div className={ParkingCardStyle.card__body_image_container}>
              <img
                src={
                  parkingArea.parkingMedia?.collection[0]?.mediaUrl?.url ||
                  NoMeidaImg
                }
                alt="area"
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isGateOptions}
        onClose={() => setIsGateOptions(false)}
        title="Select Gate"
        showHeader
      >
        <div className={ParkingCardStyle.gates}>
          {data?.gateManagers.collection.map(({ id, gate }) => (
            <div className={ParkingCardStyle.gates__item} key={`${id}/${gate}`}>
              <button
                onClick={() => {
                  navigate(
                    `/society/visitor/${
                      parkingArea.id.split("/")[2]
                    }/${encodeURIComponent(parkingArea.name)}/${
                      gate.id.split("/")[2]
                    }`
                  );
                }}
              >
                {gate.name}
              </button>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default Index;
